.addBtn {
    margin: 10px 0;
}

.myinput {
    height: 35px;
    border-radius: 0;
}

.mylabel {
    margin-bottom: 10px;
}

.container {
    height: 100%;
    padding: 10px !important;
}

.mycard {
    margin-top: 10px;
}

.phonebox {
    width: 300px;
    height: 587px;

    background: url(../../../asset/phone.png) top center no-repeat;
    background-size: 100% 100%;
    padding: 69px 20px 48px 45px;

    font-size: 16px;
    box-sizing: border-box;
    overflow: hidden;


}

.phoneboxContent {
    overflow: auto;
    height: 100%;
    padding-right: 25px;
    box-sizing: border-box;
}

.phonebox p {
    line-height: 1.7;
    margin: 0;
    font-size: 14px;
}

.phoneboxTitle {
    line-height: 26px;
    font-size: 18px;
    color: #262626;
    text-align: left;
    font-weight: 600;
    margin-bottom: 5px;
}

.dateC {
    line-height: 20px;
    font-size: 12px;
    color: #BFBFBF;
    font-weight: 400;
    margin-bottom: 15px;
}
